import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

import {alpha, Box, lighten, useTheme} from '@mui/material';

interface BaseLayoutProps {
  children?: ReactNode;
}

const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
  const theme = useTheme();
  return (
      <Box
          sx={{
              flex: 1,
              height: '100%'
          }}
      >
          <Box  sx={{
              flex: 1,
              height: 'auto',
              backdropFilter: 'brightness(0.4)',
              '.MuiPageTitle-wrapper': {
                  marginBottom: `${theme.spacing(4)}`,
                  boxShadow:
                      theme.palette.mode === 'dark'
                          ? '0 1px 0 ' +
                          alpha(lighten(theme.colors.primary.main, 0.7), 0.15) +
                          ', 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)'
                          : '0px 2px 4px -3px ' +
                          alpha(theme.colors.alpha.black[100], 0.1) +
                          ', 0px 5px 12px -4px ' +
                          alpha(theme.colors.alpha.black[100], 0.05)
              }
          }}
          >
      {children || <Outlet />}
          </Box>
          <video autoPlay loop muted  style={{
              "height": "100%",
              "width": "auto",
              "float": "left",
              "bottom": "-40%",
              "position": "fixed",
              "zIndex": "-1"
          }}>
              <source src="/static/background.mp4" type='video/mp4' />
          </video>
    </Box>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node
};

export default BaseLayout;
