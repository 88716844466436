import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Overview = Loader(lazy(() => import('src/content/overview')));

const baseRoutes = [
  {
    path: '/',
    element: <Overview />
  },
  {
    path: 'overview',
    element: <Navigate to="/" replace />
  }
];

export default baseRoutes;
