import {ChainId, Config} from "@usedapp/core";
import {multiCallContractAddress} from "../config";

const web3connectors: Config = {
    readOnlyChainId: ChainId.BSC,
    readOnlyUrls: {
        [ChainId.BSC]: 'https://bsc-dataseed1.ninicoin.io',
    },
    multicallAddresses: {
        31337: multiCallContractAddress,
        56: multiCallContractAddress
    },
}

export default web3connectors;