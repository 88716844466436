import { PartialRouteObject } from 'react-router';

import BaseLayout from 'src/layouts/BaseLayout';

import baseRoutes from './base';

const router: PartialRouteObject[] = [
  {
    path: '*',
    element: <BaseLayout />,
    children: baseRoutes
  },

  // Documentation


];

export default router;
